<template>
  <div class="leaflets-container">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <div>
      <v-container>
        <h2>
          {{ $t("leaflet.list.title") }}
        </h2>
        <v-btn
          color="primary"
          :ripple="false"
          plain
          icon
          class="d-flex justify-start w-100 pl-0"
          :to="{ name: 'Leaflet' }"
          ><v-icon>$arrowBack</v-icon>Torna al volantino</v-btn
        >
        <div v-if="getProductList(leafletId).length > 0">
          <v-row
            v-for="product in getProductList(leafletId)"
            :key="product.productId"
            no-gutters
            class="leaflets-list"
          >
            <v-col cols="3" sm="2" class="d-flex justify-center align-center"
              ><img
                :src="
                  product && product.mediaURL
                    ? product.mediaURL
                    : product.media[0].small
                "
                onerror="this.onerror=null;this.src='/no-icon.png'"
                class="product-img align-self-center"
                :alt="'Immagine' + product.name"
                :title="
                  product.name +
                    ' (' +
                    product.codInt +
                    '-' +
                    product.codVar +
                    ')'
                "
                :height="$vuetify.breakpoint.xs ? '85px' : '100px'"
            /></v-col>
            <v-col cols="8" sm="9" class="py-0">
              <v-row no-gutters class="fill-height">
                <v-col cols="12" md="6" class="py-3"
                  ><div
                    class="description default--text d-flex flex-column justify-left w-100 mt-3 ml-1 ml-sm-0"
                  >
                    <span class="name font-weight-bold">
                      {{ product.name }}
                    </span>
                    <span class="cod-int text-caption d-none">
                      cod. {{ product.codInt }}
                    </span>
                    <span class="short_descr">
                      {{ product.shortDescr }}
                    </span>
                    <span class="descr">
                      {{ product.description }}
                      <span
                        class="weight-unit"
                        v-if="
                          product.productInfos &&
                            product.productInfos.TIPOLOGIA == 'Pezzo' &&
                            product.priceUmDisplay
                        "
                      >
                        - {{ $n(product.priceUmDisplay, "currency") }}/{{
                          product.weightUnitDisplay
                        }}
                      </span>
                    </span>
                  </div></v-col
                >
                <v-col cols="12" md="6" class="d-flex align-center py-3"
                  ><ProductPrice v-if="product.priceDisplay" :product="product"
                /></v-col>
              </v-row>
            </v-col>
            <v-col cols="1" class="d-flex justify-center align-center"
              ><v-btn
                color="white"
                elevation="0"
                @click="remove(product.productId, leafletId)"
                ><v-icon color="primary">$delete</v-icon></v-btn
              ></v-col
            >
          </v-row>
        </div>
        <div v-else class="my-4">{{ $t("lists.noProducts") }}</div>
      </v-container>
      <div
        id="leaflet-menu"
        color="primary"
        class="elevation-0 grey lighten-3"
        v-if="getProductList(leafletId).length > 0"
      >
        <v-container class="d-flex">
          <v-btn color="white" @click="emptyList(leafletId)"
            ><v-icon color="primary">$delete</v-icon></v-btn
          >
          <v-spacer />
          <v-menu
            top
            left
            offset-y
            nudge-top="12"
            nudge-right="12"
            nudge-width="10"
            content-class="elevation-1 rounded-0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" color="primary">
                {{ $t("leaflet.list.useList") }}
              </v-btn>
            </template>
            <v-list nav class="leaflet-share-menu">
              <v-list-item
                class="primary--text font-weight-bold grey lighten-3 pr-6"
                @click="shareOnWA"
              >
                <v-list-item-icon class="my-1">
                  <div class="list-icon success darken-1">
                    <img
                      :src="`/img_layout/leaflet/whatsapp.svg`"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("leaflet.list.shareOnWhatsApp")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="primary--text font-weight-bold grey lighten-3 pr-6"
                @click="shareOnEmail"
              >
                <v-list-item-icon class="my-1">
                  <div class="list-icon info lighten-2">
                    <img
                      :src="`/img_layout/leaflet/email.svg`"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("leaflet.list.shareOnMail")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="primary--text font-weight-bold grey lighten-3 pr-6"
                @click="shareOnCalendar"
                ><v-list-item-icon class="my-1">
                  <div class="list-icon white calendar-border">
                    <img
                      :src="`/img_layout/leaflet/calendar.svg`"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("leaflet.list.addInCalendar")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- TODO: To un comment spesa veloce / spesa self after client's approval-->
              <!--<v-list-item
                class="primary--text font-weight-bold grey lighten-3 pr-6"
                ><v-list-item-icon class="my-1">
                  <div class="list-icon secondary pa-1">
                    <img
                      :src="`/img_layout/leaflet/self.svg`"
                      width="28px"
                      height="28px"
                    />
                  </div>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("leaflet.list.addInSpesaSelf")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>-->
            </v-list>
          </v-menu>
        </v-container>
      </div>
    </div>

    <v-dialog max-width="300px" v-model="modal">
      <div class="w-100 close-date-picker">
        <v-btn
          @click="closeModal"
          color="white"
          class="elevation-0 d-flex ml-auto"
          small
          fab
          icon
          ><v-icon>icon-close</v-icon></v-btn
        >
      </div>

      <div v-if="step == 0">
        <v-date-picker
          v-model="selectedDate"
          scrollable
          actions
          :max="maxDate | dayjs('format', 'YYYY-MM-DD')"
          :min="minDate | dayjs('format', 'YYYY-MM-DD')"
          no-title
        >
        </v-date-picker>
        <div class="px-4">
          <v-btn
            class="w-100"
            :disabled="!selectedDate"
            color="primary"
            @click="nextStep"
            >{{ $t("leaflet.calendar.confirmDate") }}</v-btn
          >
        </div>
      </div>

      <div v-if="step == 1">
        <v-time-picker
          @input="updateDatetime"
          format="24hr"
          v-model="selectedTime"
          :landscape="landscape"
        ></v-time-picker>
        <div class="w-100 d-flex flex-column  px-4">
          <v-btn class="my-7" outlined color="primary" @click="previousStep">{{
            $t("common.back")
          }}</v-btn>
          <v-btn
            color="primary"
            :disabled="!selectedDatetime"
            @click="createICS"
            >{{ $t("common.confirm") }}</v-btn
          >
        </div>
      </div>

      <div class="spesa-timeslot-selector my-5 px-5 d-flex">
        <!-- CONFERM BUTTON ONCE  DATE IS SELECTED-->
        <!-- GO BACK WHEN THE DATE IS SELECTED AND TIME PICKER IS ACTIVE-->
        <!---->
      </div>
    </v-dialog>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block category-block-3"
    />

    <v-container>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_DESCRIPTION"
        tag="div"
      />
    </v-container>
  </div>
</template>
<style lang="scss">
.leaflets-list {
  border-bottom: 2px solid var(--v-grey-lighten3);
}
.close-date-picker {
  background-color: var(--v-primary-base);
}
.leaflet-share-menu {
  .list-icon {
    // background-color: var(--v-success-darken2);
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 3px;
  }
  .calendar-border {
    border: 2px solid $primary !important;
    padding: 6px;
  }
}
.v-time-picker-title {
  justify-content: center;
}
.v-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  &__title {
    border-radius: 0 !important;
    margin: 0 auto;
    width: 100%;
    padding-top: 0;
  }
}
.v-application .accent--text {
  color: var(--v-black) !important;
}
.v-btn.v-btn--outlined {
  border-color: var(--v-black) !important;
}
.spesa-timeslot-selector {
  gap: 10px;
  > button {
    flex: 1 1 0;
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
import { saveAs } from "file-saver";
import categoryMixin from "~/mixins/category";
import clickHandler from "~/mixins/clickHandler";
import get from "lodash/get";
import has from "lodash/has";
import ICS from "vue-ics";
import Vue from "vue";
import leafletService from "~/service/leafletService";
import { mapActions, mapGetters } from "vuex";

Vue.use(ICS);
export default {
  name: "Leaflets",
  components: {
    ProductPrice
  },
  mixins: [categoryMixin, clickHandler],
  data() {
    return {
      step: 0,
      calendarEvent: null,
      leafletId: null,
      leaflet: null,
      modal: false,
      dialog: false,
      minDate: null,
      maxDate: null,
      selectedDate: null,
      selectedTime: "00:00",
      selectedDatetime: null,
      landscape: false,
      showTimePicker: false,
      showDatePicker: true
    };
  },
  computed: {
    ...mapGetters({
      getProductList: "custom/getProductsByLeafletId"
    })
  },
  methods: {
    ...mapActions({
      emptyList: "custom/emptyLeafletList",
      removeFromList: "custom/removeFromLeafletList"
    }),

    async empty() {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("Sei sicuro di voler svuotare la lista?")
      });
      if (res) this.emptyList();
    },
    async remove(productId, leafletId) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("Sei sicuro di voler rimuovere il prodotto?")
      });
      if (res) this.removeFromList({ productId, leafletId });
    },
    shareOnWA() {
      //let share = this.createStringList(true);
      // let share = encodeURIComponent(this.createStringList(true).join("\n\n"));
      let share = encodeURIComponent(this.createStringList(true).join("\r\n"));
      // window.location.href = `https://api.whatsapp.com/send?text=${share}`;
      if (this.$platform_is_cordova) {
        window.cordova.InAppBrowser.open(
          "https://api.whatsapp.com/send/?phone&text=" + share,
          "_system"
        );
      } else {
        if (
          /android|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          window.open(
            "https://api.whatsapp.com/send/?phone&text=" + share,
            "_blank"
          );
        } else {
          window.open(
            "https://web.whatsapp.com/send/?phone&text=" + share,
            "_blank"
          );
        }
      }
    },
    shareOnEmail() {
      let share = encodeURIComponent(this.createStringList().join("\n\n"));
      window.location = `mailto:?subject=LA MIA LISTA DELLA SPESA DA DEMO Volantino ${this.leaflet.title} &body=${share}`;
    },
    async shareOnCalendar() {
      this.modal = true;
    },
    updateDatetime() {
      this.selectedDatetime = `${this.selectedDate} ${this.selectedTime}`;
      console.log("final", this.selectedDatetime);
    },
    async createICS() {
      if (this.selectedDatetime) {
        this.modal = false;
        const icsHour = this.$dayjs(this.selectedDatetime).toDate();
        const icsDescription = this.createStringList()
          .join("\n")
          .replace(/\r?\n/g, "\\n")
          .replace("\r", "");

        this.$ics.addEvent(
          "it",
          "LA MIA LISTA DELLA SPESA DA DEMO Volantino " + this.leaflet.title,
          icsDescription,
          "",
          this.$dayjs(this.selectedDatetime),
          icsHour
        );
        let events = this.$ics.calendar();
        let calendar = events
          .trim()
          .split("\n")
          .join("\n")
          .replace(/^\s*[\r\n]/gm, "")
          .replace(/^\s+/gm, "");

        // Create calendar event for Mobile Apps (iOS and Android)

        if (this.$platform_is_cordova) {
          //eslint-disable-next-line no-undef
          let platform_device = device ? device.platform.toLowerCase() : "ios";
          if (platform_device == "ios" && window.plugins.calendar) {
            try {
              await this.calendarAccess();
            } catch (error) {
              console.log(error);
            }
          } else if (platform_device != "ios") {
            this.createAppCalendarEvent();
          } else {
            //
          }
        } else {
          // Create calendar Event on Desktop and Mobile Site
          this.calendarEvent =
            "data:text/calendar;charset=utf-8," + encodeURIComponent(calendar);
          saveAs(this.calendarEvent, "Tigros.ics");
        }

        //this.$ics.download("Tigros");
        this.$ics.removeAllEvents();
        this.closeModal();
      }
    },

    /**
     * Check necessary permission to create calendar event for iOS17+
     */
    async calendarAccess() {
      let _this = this;
      return new Promise((resolve, reject) => {
        // Check if we have permission to access the calendar
        window.plugins.calendar.hasReadWritePermission(
          result => {
            if (result === true) {
              // We already have permission
              console.log("We have calendar permissions.");

              _this.createAppCalendarEvent();
              resolve(true); // Resolve the promise with true, indicating permission is already granted
            } else {
              // We do not have permission, so let's request it
              window.plugins.calendar.requestReadWritePermission(
                () => {
                  // Permission request was successful
                  console.log("Calendar permissions granted.");

                  _this.createAppCalendarEvent();
                  resolve(true); // Resolve the promise with true, indicating permission is granted now
                },
                err => {
                  // Permission request failed
                  console.error("Calendar permissions were not granted:", err);
                  reject(err); // Reject the promise with the error
                }
              );
            }
          },
          err => {
            // Error occurred while checking permissions
            console.error("Error checking calendar permissions:", err);
            reject(err); // Reject the promise with the error
          }
        );
      });
    },
    createAppCalendarEvent() {
      var _t = this;
      var createCalOptions = window.plugins.calendar.getCreateCalendarOptions();
      createCalOptions.calendarName = "Tigros";
      createCalOptions.calendarColor = "#312E81";

      window.plugins.calendar.createCalendar(
        createCalOptions,
        function(data) {
          console.log(data);
          var calOptions = window.plugins.calendar.getCalendarOptions(); // grab the defaults

          window.plugins.calendar.createEventWithOptions(
            _t.leaflet.title + " - Lista della spesa",
            "",
            _t.createStringList().join("\n\n"),
            new Date(_t.selectedDatetime),
            new Date(_t.selectedDatetime),
            calOptions,
            function(result) {
              console.log(result);
            },
            function(err) {
              console.log(err);
            }
          );
        },
        function(error) {
          console.log(error);
        }
      );
    },
    nextStep() {
      this.step = this.step + 1;
    },
    previousStep() {
      this.step = this.step - 1;
    },
    /**
     * Close the create calendar event modal
     */
    closeModal() {
      this.modal = false;
      /**
       * Delay clearing data to allow the plugin to create the event on the calendar
       */
      setTimeout(this.resetModalData, 2000);
    },
    /**
     * Rest modal data to initial values
     */
    resetModalData() {
      this.selectedDate = null;
      this.selectedTime = "00:00";
      this.selectedDatetime = null;
      this.step = 0;
    },
    createStringList(whatsapp = false) {
      return [
        "LA MIA LISTA DELLA SPESA DA Demo\n\n\rVolantino " +
          this.leaflet.title +
          "\nOfferte valide dal " +
          this.$dayjs(this.leaflet.fromDate).format("D MMMM") +
          " al " +
          this.$dayjs(this.leaflet.toDate).format("D MMMM") +
          "\n\n\r",
        ...this.getProductList(this.leafletId).map((item, index) => {
          return `${index + 1}) ${[
            get(item, "name", ""),
            get(item, "shortDescr", ""),
            get(item, "description", "") + " - ",
            this.getPriceDiscounted(item, whatsapp)
          ].join(" ")}`;
        })
      ];
    },
    getPrice(item, whatsapp) {
      if (
        has(item, "price") &&
        has(item, "priceDisplay") &&
        item.price != item.priceDisplay
      ) {
        return (
          (whatsapp ? "~" : "") + "€" + " " + item.price + (whatsapp ? "~" : "")
        );
      }
      return "";
    },
    getPriceDiscounted(item, whatsapp) {
      if (has(item, "priceDisplay")) {
        return (
          (whatsapp ? "*" : "") +
          "€" +
          " " +
          item.priceDisplay +
          (whatsapp ? "*" : "")
        );
      }
      return "";
    },
    async fetchLeaflet() {
      return await leafletService.getLeafletById(this.leafletId);
    }
  },
  async mounted() {
    this.leafletId = this.$route.params.leafletId;
    this.leaflet = await this.fetchLeaflet();
    this.minDate = new Date();
    this.maxDate = new Date(this.leaflet.toDate);
  },
  watch: {
    "$route.params.leafletId": async function() {
      this.leafletId = this.$route.params.leafletId;
    }
  }
};
</script>
